@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";

.option-item-group {
  border-bottom: 1px solid colors.$grey5;

}

.option-item {
  @include mixin.flexible(row, flex-start, center);
  padding: 8px 12px;
  margin: 4px 0;
  cursor: pointer;

  .prefix-icon {
    object-fit: contain;
    height: 22px;
    width: 24px;
    margin-right: 8px;
  }

  .tag {
    margin-left: 8px;
  }

  .text {
    @include typography.body-small;
    color: colors.$darkGrey;
  }

  &:hover {
    background: colors.$indigo5;

    > .text {
      color: colors.$indigo;
    }
  }

  &.sign-out-container {
    @include mixin.flexible(row, flex-start, center);

    svg {
      @include mixin.size(16px);

      path {
        fill: colors.$danger;
      }
    }

    &:hover {
      background: colors.$danger5;
    }

    .text {
      margin-left: 8px;
      color: colors.$danger;
    }
  }
}

@media only screen and (max-width: variables.$mobile) {
  .option-item {
    padding: 12px 0;

    .text {
      @include typography.body-small;
    }

    &:hover {
      background: colors.$indigo5;

      .text {
        color: colors.$indigo;
      }
    }

    &.sign-out-container {
      @include mixin.flexible(row, flex-start, center);

      svg {
        @include mixin.size(16px);

        path {
          fill: colors.$danger;
        }
      }

      &:hover {
        background: colors.$danger5;
      }

      .text {
        margin-left: 8px;
        color: colors.$danger;
      }
    }
  }

}